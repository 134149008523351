body {
  background-size: cover;
  background-position-y: 0;
}

.min-width-1200px {
  min-width: 1200px;
}

.min-width-992px {
  min-width: 993px;
}

.bg-lightgreen {
  background-color: #e9f6ec !important;
}

.wrapper {
  &:before {
    display: none;
  }
}

.wrapper.overflow-x-visible {
  &:before {
    min-width: 992px;
  }
}

.brand-img {
  border-radius: 0.5rem;
}

.border-radius-none {
  border-radius: 0 !important;
}

.user-name {
  width: 80px;
  height: 80px;
  font-size: 1rem;
  background-color: #e9f6ec;
}

.sidebar-brand,
.sidebar-brand:hover {
  margin: 0 auto;
  max-width: 200px;
  background: transparent;
}

.btn-white {
  background-color: white;
}

.text-custom-red {
  color: #dc3545;
}

.text-underline {
  text-decoration: underline !important;
}

.max-width-20 {
  max-width: 20px;
}

.min-width-30 {
  min-width: 30px;
}

.min-width-50 {
  min-width: 50px;
}

.min-width-100 {
  min-width: 100px;
}

.max-width-150 {
  max-width: 150px;
}

.max-width-300 {
  max-width: 300px;
}

.min-width-300 {
  min-width: 300px;
}

.min-width-lg {
  min-width: 992px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-height-50 {
  min-height: 50%;
}

.min-height-250 {
  min-height: 250px;
}

.max-height-45 {
  max-height: 45px;
}

.height-100 {
  height: 100%;
}

.bg-transparent {
  background: transparent !important;
}

.bg-lighter {
  background: #eeeeee4f !important;
}

.font-size-75 {
  font-size: 0.75rem;
}

.font-size-85 {
  font-size: 0.85rem;
}

.font-size-95 {
  font-size: 0.95rem;
}

.font-size-65 {
  font-size: 0.65rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.align-content-evenly {
  align-content: space-evenly;
}

.word-break-all {
  word-break: break-all;
}

.min-width-150 {
  min-width: 150px;
}

.max-width-250 {
  max-width: 250px;
}

.max-width-100 {
  max-width: 100%;
}

.max-width-200 {
  max-width: 200px;
}

.width-170 {
  width: 170px;
}

.width-170 {
  width: 170px;
}

.width-250 {
  width: 250px;
}

.z-index-2 {
  z-index: 2;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-hidden {
  overflow-x: visible !important;
}

.footer {
  background: #ffffffad;
}

.absolute-right {
  position: absolute;
  right: 0;
}

.bg-light {
  background-color: #e9ecef !important;
}

.work-order-details-nav {
  .nav-link {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .nav-link:not(.active) {
    border: none !important;
  }

  .nav-link.active {
    background-color: white;
    border-bottom: none;
    font-weight: 500;
  }
}

.conflict i {
  color: #dc3545 !important;
}

.setting-switch {
  .custom-control-label {
    padding-top: 2px;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}

.font-300 {
  font-weight: 300;
}

.date-picker .DayPicker-Day {
  color: #8b9898;
  font-size: 0.875em;

  &.DayPicker-Day--today {
    font-weight: 400;
  }
}

.date-range-picker {
  font-size: inherit;
}

.date-range-picker
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
    .DayPicker-Day--end
  ):not(.DayPicker-Day--outside) {
  background-color: #4a90e21f !important;
  color: #4a90e2;
}

.date-range-picker .DayPicker-Day {
  border-radius: 0 !important;
}

.date-range-picker .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.date-range-picker .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.calendar-container {
  width: 100%;
  padding: 20px 20px 0 20px;
  border: 1px solid #d4e0f3;
  background: #f6faff;
  border-radius: 5px;
}

.box-shadow-none {
  box-shadow: none;
}

.ql-container.ql-snow {
  min-height: 100px;
}

.ql-toolbar {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #dee2e6 !important;
  font-family: inherit;
}

.ql-container {
  font-family: inherit;
  border: none !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

button {
  font-weight: 500 !important;
}

.row-expansion-style {
  padding: 0 !important;

  .list-group-item:last-child {
    border-bottom: none !important;
  }
}

.b-grid-header {
  background-color: #e9f6ec !important;
}

.expandable-task {
  display: flex !important;
  align-items: flex-start !important;
  min-height: 30px !important;
  padding-top: 3px !important;
  justify-content: space-between !important;
}

.react-bs-table-sizePerPage-dropdown {
  float: left;
}

//Truck / Trailer column tooltip on Job Schedule
#b-tooltip-1 {
  display: none;
}

.upper-close {
  position: absolute;
  right: -8px;
  top: -12px;
}

.progress-container {
  margin: 0 auto;
}

.progress-line {
  height: 1px;
  background: #dee2e6;
}

.status-container {
  z-index: 1;
  width: 100%;
  margin-top: -10px;
}

.link {
  text-decoration: underline !important;
  cursor: pointer;
  color: #0267a4 !important;
}

.border-radius-bottom-none {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.search-input.input-group-text {
  border-radius: 0.2rem 100% 90% 0.2rem !important;
}

.custom-rounded-button {
  padding: 8px;
}

.usage-report-cells {
  width: 75px;
  border-right-color: white;
  border-left-color: white;
  border-left-style: solid;
  border-right-style: solid;
  border-left-width: 2px;
  border-right-width: 2px;
}

.carousel-caption h3 {
  color: white;
}

.py-05 {
  padding-top: 1.5px !important;
  padding-bottom: 1.5px !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.iconButton {
  background-image: none !important;
}

.fm-cell {
  white-space: pre-wrap !important;
}

.nav-tabs {
  margin-bottom: -1px;
}

.rounded-0 {
  border-radius: 0 !important;
}

.z-index-n1 {
  z-index: -1;
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-height: 60px;
}